/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/dist/css/bootstrap.min.css';
/*@import 'font-awesome/css/font-awesome.css';*/
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';

@import 'assets/google-sans-pro.css';
@import '../src/assets/material-icons.css';
:root {
    --color-primary: #1a2188;
    --color-secondary: #2998e3;
}

html {
    min-height: 100%;
    position: relative;
    background-color: #f2f2f2;
}

body {
    background-color: #f2f2f2;
    height: 100%;
    font-family: Source Sans Pro;
    color: #666e76;
}

body.modal-open {
    overflow: visible !important;
    /* position: fixed; */
    width: 100%;
}

.body-content {
    padding-top: 15px;
    padding-bottom: 80px;
    background-color: #f2f2f2;
    max-width: 1400px;
}

button {
    cursor: pointer;
    color: #fff;
}

button:hover {
    opacity: 0.8;
}

h1 {
    font-weight: 300;
    font-size: 2.5em;
}

p {
    font-size: 1.2em;
}

.primary-bg {
    background-color: purple;
}

.secondary-bg {
    background-color: #40c0cb;
}

.tertiary-bg {
    background-color: #005f70;
}

.primary-text {
    color: purple;
}

.secondary-text {
    color: #40c0cb;
}

.tertiary-text {
    color: #005f70;
}

.loadingModal {
    /* Center the modal on the screen */
    top: 50%;
    transform: translateY(-25%);
}

.loadingModal .alert {
    margin-bottom: 0px;
    text-align: center;
    padding: 40px;
}

.btn {
    font-size: 12px;
}

.btn-link {
    padding: 0px;
    font-weight: 400;
    font-size: 1em;
}

.footer-link {
    cursor: pointer;
}

a.footer-link:hover {
    color: #ffffff !important;
    text-decoration: underline !important;
}

a.footer-link:visited,
a.footer-link:active {
    color: #ffffff !important;
}

a,
.btn-link {
    color: #00a7c4;
    font-weight: bold;
}

.card.next-appt {
    min-height: 350px;
}

.pointer {
    cursor: pointer;
}

input.ng-valid[required],
select.ng-valid[required],
input.ng-valid.required,
select.ng-valid.required {
    border-left: 5px solid #42a948;
    /* green */
}

select.ng-invalid:not(form),
input.ng-invalid:not(form) {
    border-left: 5px solid #a94442;
    /* red */
}

.fs-modal .modal-dialog {
    max-width: 1400px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.tq-modal .modal-dialog {
    max-width: 73%;
    margin-top: 30px;
    margin-bottom: 0px;
}

.scheduler-panel .card-header {
    padding: 0px !important;
}

.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.services-list {
    padding-bottom: 30px;
}

.next-appts {
    padding-bottom: 30px;
}

.bold {
    font-weight: bold;
}

.print-only {
    display: none;
}

button.input-group-addon {
    color: #495057;
}
/* Ng-Bootstrap ToolTip overrides */
.tooltip.show {
    opacity: 1 !important;
}

.tooltip-inner {
    background-color: transparent !important;
}

/* End Ng-Bootstrap ToolTip overrides */

@media print {
    .welcome,
    footer,
    nav {
        display: none !important;
    }

    .body-content {
        padding-bottom: 0px;
    }

    .screen-only {
        display: none;
    }

    .print-only {
        display: inherit;
    }

    .container {
        width: auto;
    }

    @page {
        size: auto;
        /* auto is the current printer page size */
        margin: 0mm;
        /* this affects the margin in the printer settings */
    }
}

/* xs */
@media screen and (max-width: 575px) {
    .tq-modal .modal-dialog {
        max-width: 98%;
    }
    .modal-dialog {
        max-height: calc(90vh - 210px);
    }
    footer {
        font-size: 0.7em;
    }
}

/* sm */
@media screen and (min-width: 576px) and (max-width: 767px) {
    footer {
        font-size: 0.9em;
    }
}

/* md */
@media screen and (min-width: 768px) and (max-width: 991px) {
}

/* Up to md */
@media screen and (max-width: 991px) {
    .next-appts > div {
        padding-bottom: 20px;
    }
}

/* lg and up */
@media screen and (min-width: 992px) {
    .avatar {
        font-size: 10px;
    }
}

.userdatacapture-modal-window .modal-dialog,
.userbiometriccapture-modal-window .modal-dialog {
    max-width: 600px;
}

.terms-and-privacy-modal .modal-dialog,
.terms-and-privacy-modal .modal-dialog {
    max-width: 600px;
}

.warning-message {
    font-size: 16px;
    font-weight: bold;
}

#somelement {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.dropdown-menu {
    position: inherit !important;
}

.dropdown-menu.show {
    z-index: 1090 !important;
}

@media (min-width: 1450px) {
    .psc-modal-wide .modal-dialog .modal-content {
        width: 1300px !important;
        margin-left: -350px !important;
    }
}

@media (min-height: 1000px) {
    .psc-modal-tall .modal-dialog {
        height: 800px !important;
    }

    .psc-modal-tall .modal-dialog .modal-body {
        max-height: 800px !important;
    }

    .psc-modal-tall .modal-dialog .psc-row-height {
        height: 600px !important;
    }

    .psc-modal-tall .modal-dialog .psc-row-height .psc-result-height {
        height: 550px !important;
    }

    .psc-modal-tall .modal-dialog .psc-row-height .pager {
        max-height: 50px !important;
    }
}
.psc-modal {
    overflow-y: initial !important;
    .modal-body {
        max-height: calc(100vh-250px);
        overflow-y: auto;
    }
}
a.page-link {
    text-align: center;
}

nav.widget-nav {
    margin-top: 10px;
}

.les-information-text {
    font-size: 1rem;
    line-height: 1.5;
}

.les-information-text p {
    font-size: 1rem;
    line-height: 1.5;
}

label.les-label {
    font-weight: 600;
    font-size: 1em;
    margin-bottom: auto;
}

.les-sencondary-label {
    padding-left: 15px;
    font-size: 1em;
}

.les-input-view-mobile {
    max-width: 446px;
    margin: auto;
}
/*
Widget consideration
*/
.primary-widget-container input.form-check-input {
    margin-left: auto;
}

.custom-card {
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.les-widget-icon .no-outline:focus {
    outline: none;
    box-shadow: none;
}

.les-widget.btn-outline-primary:not([disabled]):not(.disabled).active,
.les-widget.btn-outline-primary:not([disabled]):not(.disabled):active,
.show > .les-widget.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    box-shadow: none;
}

label.les-widget.btn-outline-primary {
    color: var(--color-secondary);
    background-color: transparent;
    background-image: none;
    border-color: var(--color-secondary);
}

label.les-widget.btn-outline-primary:hover {
    color: white;
    background-color: var(--color-secondary);
    background-image: none;
    border-color: var(--color-secondary);
}

a.les-widget-link,
button.les-widget-link {
    position: relative;
    display: inline;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: white;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    margin: 1px;
    text-align: center;
}

a.les-widget-link:hover,
button.les-widget-link:hover {
    color: white;
    background-color: rgb(26 33 136 / 88%);
    border: 1px solid var(--color-primary);
}

a.les-widget-link:not([href]):not([tabindex]),
button.les-widget-link:not([href]):not([tabindex]) {
    color: white;
    text-decoration: none;
}

/*end Widget consideration*/
